import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d15fcb7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab_title" }
const _hoisted_2 = { class: "report_con" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_ReportDetail = _resolveComponent("ReportDetail")!
  const _component_ReportTable = _resolveComponent("ReportTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.packageActiveKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.packageActiveKey) = $event)),
      onTabClick: _ctx.tabClick,
      class: "rays_tuner_optimize_report_tab"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packages, (item, index) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: index.toString()
          }, {
            tab: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(item), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeKey", "onTabClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ReportDetail, { detail: _ctx.detail }, null, 8, ["detail"]),
      _createVNode(_component_ReportTable, {
        class: "table",
        packageName: _ctx.packageName,
        onVariantChange: _ctx.variantChange
      }, null, 8, ["packageName", "onVariantChange"])
    ])
  ], 64))
}