
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  unref,
  reactive,
  watch,
  provide,
} from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import PerfectScrollbar from 'perfect-scrollbar';
import { imgSvg96_64 as imgSvg } from '@/utils/img';
import VariantCard from '@/views/shader-detect/components/VariantCard.vue';
import { debounce } from 'lodash';
import { message } from 'ant-design-vue';
import { SelectTypes } from 'ant-design-vue/es/select';
import api from '@/api/shader-detect';
import screenApi from '@/api/shader-tools';
import { convertRes2Blob } from '@/utils/download';
import { formatVariant } from '@/utils';
import store from '@/vuex/store';
import { getBaseUrl } from '@/api/service';
import router from '@/routes';
const engineurl = getBaseUrl('engine');

type element_null = Element | null;
type Key = ColumnProps['key'];

const reportColumns = [
  {
    title: 'Shader',
    slots: { customRender: 'shader' },
    width: '30%',
    key: 'shader',
    fixed: 'left',
  },
  {
    title: '所属PAT',
    dataIndex: 'pat',
    key: 'pat',
    slots: { customRender: 'pat' },
    width: '8%',
  },
  {
    title: '简化变体',
    slots: { customRender: 'simplify' },
    key: 'simplify',
    width: '11%',
  },
  {
    title: '筛选变体',
    slots: { customRender: 'screen' },
    dataIndex: 'screen',
    key: 'screen',
    width: '11%',
  },
  {
    title: '选用变体',
    slots: { customRender: 'quality' },
    key: 'quality',
    width: '40%',
  },
];

export default defineComponent({
  name: 'ReportTable',
  components: {
    VariantCard,
  },
  emits:['variantChange'],
  props: {
    packageName: {
      type: String,
    },
  },
  setup(prop, context) {
    let reportTableDom: element_null = null;
    let reportTablePs: any = null;
    let reportInputDom: element_null = null;
    let reportInputPs: any = null;
    const $_user = store.state.user.userinfo;
    const toolsShow = ref<boolean>(false);
    const statusOpts = ref<SelectTypes['options']>([
      {
        value: -1,
        label: '全部',
      },
      {
        value: 0,
        label: '待创建 highlight',
      },
      {
        value: 1,
        label: 'highlight 生成中',
      },
      {
        value: 2,
        label: '待简化',
      },
      {
        value: 3,
        label: '简化中',
      },
      {
        value: 4,
        label: '待筛选',
      },
      {
        value: 5,
        label: '筛选中',
      },
      {
        value: 6,
        label: '待检测',
      },
      {
        value: 7,
        label: '检测中',
      },
      {
        value: 8,
        label: '已检测',
      },
    ]);
    const paginationProps = ref({
      size: 'small',
      showQuickJumper: true,
      position: 'bottom',
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: (current) => {
        console.log('paginationProps', current);
        paginationProps.value.current = current;
        search.offset = current - 1;
        getReports();
      },
    });
    const search = reactive({
      /**用户名 */

      user: $_user.email,
      package_name: '',
      offset: 0,
      count: paginationProps.value.pageSize,
      status: -1,
      load_rate: '',
      error: '',
      pat: '',
      tag: -1,
      shader_name: '',
    });
    const clearSearch = () => {
      search.status = -1;
      search.pat = '';
      search.tag = -1;
      search.load_rate = '';
      search.shader_name = '';
      search.error = '';
    };

    const selectedRowKeys = ref<Key[]>([]);
    const selectAll = ref<boolean>(false);
    const onSelectChange = (changableRowKeys: Key[]) => {
      console.log(
        'selectedRowKeys changed: ',
        changableRowKeys,
        selectedRowKeys.value
      );
      selectedRowKeys.value = changableRowKeys;
      selectAll.value = false;
    };
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: '全选所有',
            onSelect: (changableRowKeys: Key[]) => {
              console.log('all-data');
              selectedRowKeys.value = changableRowKeys;
              selectAll.value = true;
            },
          },
          {
            key: 'cur-page',
            text: '全选当前页',
            onSelect: (changableRowKeys: Key[]) => {
              selectedRowKeys.value = changableRowKeys;
              selectAll.value = false;
            },
          },
        ],
      };
    });
    const reportData = ref<any>([]);
    watch(
      () => prop.packageName,
      () => {
        console.log('prop packageName watch', prop.packageName);
        search.package_name = (prop.packageName || '') as string;
        clearSearch();
        getReports();
        getPats();
      }
    );
    const patsOpts = ref<any>([
      {
        value: '',
        label: '全部',
      },
    ]);
    const getPats = async () => {
      const params = {
        user: $_user.email,
        package_name: search.package_name,
      };

      const res = await api.getPatsOfPackage(params);
      if (!res) return;
      if (res['code'] == 0) {
        const _data = JSON.parse(res['result_list'] || '[]');
        console.log('getPats', res, _data);
        patsOpts.value = [
          {
            value: '',
            label: '全部',
          },
        ];
        for (let i = 0; i < _data.length; i++) {
          patsOpts.value.push({
            value: _data[i],
            label: _data[i],
          });
        }
      } else {
        message.error(`获取当前游戏包的所有pat失败：${res['detail'] || ''}`);
      }
    };

    // const patIdReg = /;[0-9]+,/g;
    const getReports = async () => {
      const param = JSON.parse(JSON.stringify(search));
      if (param.load_rate == '') {
        param.load_rate = -1;
      } else if (
        isNaN(parseFloat(param.load_rate)) ||
        !isFinite(param.load_rate)
      ) {
        message.error('筛选条件（重载率）应该为数字！');
        return;
      }
      if (param.error == '') {
        param.error = -1;
      } else if (isNaN(parseFloat(param.error)) || !isFinite(param.error)) {
        message.error('筛选条件（Error）应该为数字！');
        return;
      }
      selectedRowKeys.value = [];
      const res = await api.getOptimizationResultInfoByFeatures(param);
      if (!res) return;
      if (res['code'] == 0) {
        const _data = JSON.parse(res['result_list'] || '[]');
        console.log('getReports', res, _data);
        for (let i = 0; i < _data.length; i++) {
          _data[i].key = i;
          const _height = _data[i].highlight_img.split(',');
          _data[i].highlight =
            engineurl +
            '/api/sopt/simplifier/getHighlightByFilename?filename=' +
            _height[2] +
            '&pat=' +
            _height[1] +
            '&user=' +
            $_user.email +
            '&pid=' +
            _height[0];

          // const _pats = (';' + _data[i].of_pats)
          //   .replaceAll(patIdReg, ';')
          //   .split(';');
          // const _patsRes: string[] = [];
          // for (let j = 1; j < _pats.length - 1; j++) {
          //   if (!_patsRes.includes(_pats[j])) {
          //     _patsRes.push(_pats[j]);
          //   }
          // }
          const _pats = _data[i].of_pats.split(';');
          const _patsRes: string[] = [];
          for (let j = 0; j < _pats.length - 1; j++) {
            if (!_patsRes.includes(_pats[j])) {
              _patsRes.push(_pats[j]);
            }
          }
          _data[i].pats = _patsRes;
        }
        reportData.value = _data;
        paginationProps.value.total = res['total'];
      } else if (res['code'] == 34) {
        message.info(`列表${res['detail']}，正在生成。。。`);
      } else {
        message.error(`获取优化报告列表数据失败：${res['detail'] || ''}`);
      }
    };

    provide('getChangeVariants', async ({ pats, shader_md5 }) => {
      console.log('table getChangeVariants', pats, shader_md5);
      let _res: any = [];
      const params = {
        user: $_user.email,
        package_name: search.package_name,
        shader_md5: shader_md5,
        of_pats: pats,
      };

      const res = await api.getVariantListSelected(params);
      if (!res) return;
      if (res['code'] == 0) {
        const _data = JSON.parse(res['result_list'] || '[]');
        console.log('getVariantListSelected', res, _data);
        _res = _data;
      } else {
        message.error(`获取shader下所有已筛选变体失败：${res['detail'] || ''}`);
      }
      // for (let i = 1; i <= 30; i++) {
      //   const _tmpTag = i - Math.floor(i / 2) * 2;
      //   const _tmpTag2 = i - Math.floor(i / 3) * 3;
      //   _res.push({
      //     variant_id: `com.miHoYo.Yuanshen.${i}.pat,${i},20220423184302-000${i},_2_${i}m,0.00034,-0.3246,${_tmpTag},${_tmpTag2}`,
      //   });
      // }
      // await new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve(1);
      //   }, 1000);
      // });
      return _res;
    });

    provide('getVariantTasks', async ({ pat, pid, variant }) => {
      console.log('table getVariantTasks', pat, pid, variant);
      let _res: any = [];
      const params = {
        user: $_user.email,
        variant_id: `${pat},${pid},${variant}`,
      };

      const res = await api.getDetectionTasksOfVariant(params);
      if (!res) return;
      if (res['code'] == 0) {
        const _data = JSON.parse(res['result_list'] || '[]');
        console.log('getDetectionTasksOfVariant', res, _data);
        _res = _data;
      } else {
        message.error(
          `获取目标变体参与的所有画质检测任务失败：${res['detail'] || ''}`
        );
      }
      // for (let i = 1; i <= 30; i++) {
      //   const _tmpTag = i - Math.floor(i / 9) * 9;
      //   _res.push({
      //     task_number: '2021111522240520' + i,
      //     status: _tmpTag,
      //     intro: '设备有问题' + i,
      //   });
      // }
      // await new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve(1);
      //   }, 1000);
      // });
      return _res;
    });

    const toUrl = (type, item) => {
      console.log('toUrl', type, item);
      // if (condition) {

      // }
    };

    const downloadFile = async (obj) => {
      if (!obj.task_id || !obj.variant) {
        return;
      }
      const params = {
        type: parseInt(obj.type) || 0,
        user: $_user.email,
        pat: obj.pat,
        shader_id: obj.shader_id,
        task_id: obj.task_id,
        variant: obj.variant,
      };
      const res = await screenApi.downloadFile(params, {
        returnRes: true,
        responseType: 'blob',
      });
      if (!res) return;
      if (res.statusText == 'OK' && !res.data.code) {
        convertRes2Blob(res);
      } else {
        message.error('获取下载数据失败:' + (res.data.detail || ''));
      }
    };

    const changeVariant = async (obj) => {
      console.log('changeVariant', obj);
      const params = {
        tag: obj.tag,
        user: $_user.email,
        package_name: search.package_name,
        shader_md5: obj.shader_md5,
      };
      let _msg = '重置';
      if (obj.tag == 1) {
        _msg = '设置';
        params['of_pats'] = obj.of_pats;
        params['variant_id'] = obj.variant_id;
      }

      const res = await api.setAdnResetSelectedVariant(params);
      if (!res) return;
      if (res['code'] == 0) {
        message.success(`${_msg}选中变体成功`);
        getReports();
        context.emit('variantChange');
      } else {
        message.error(`${_msg}选中变体失败：${res['detail'] || ''}`);
      }
    };

    const toQuaily = ({ pat, taskNumber }) => {
      router.push({
        path: `/imageQualityDetail/${pat}/${taskNumber}`,
      });
    };
    const searchClick = () => {
      paginationProps.value.current = 1;
      search.offset = 0;
      getReports();
    };

    const allDownload = async ({ key }) => {
      // selectedRowKeys.value = changableRowKeys;
      const params = {
        type: parseInt(key) || 0,
        user: $_user.email,
        package_name: search.package_name,
        mode: selectAll.value ? 0 : 1,
      };
      if (selectedRowKeys.value.length < 1) {
        message.warning('请先选择Shader');
        return;
      }
      if (selectAll.value) {
        const _search = JSON.parse(JSON.stringify(search));
        if (_search.load_rate == '') {
          _search.load_rate = -1;
        } else if (
          isNaN(parseFloat(_search.load_rate)) ||
          !isFinite(_search.load_rate)
        ) {
          message.error('筛选条件（重载率）应该为数字！');
          return;
        }
        if (_search.error == '') {
          _search.error = -1;
        } else if (isNaN(parseFloat(_search.error)) || !isFinite(_search.error)) {
          message.error('筛选条件（Error）应该为数字！');
          return;
        }
        params['features'] = _search;
      } else {
        params['variants'] = [];
        for (let i = 0; i < selectedRowKeys.value.length; i++) {
          const _item = formatVariant(reportData.value[(selectedRowKeys.value[i]) as number]?.variant_id);
          if (_item.pat == '-1') {
            continue;
          }
          params['variants'].push({
            pat: _item.pat,
            shader_id: _item.pid,
            task_id: _item.taskId,
            variant_id: _item.variant
          })
        }
      }
      const res = await api.batchFileDownload(params, {
        returnRes: true,
        responseType: 'blob',
      });
      if (!res) return;
      if (res.statusText == 'OK' && !res.data.code) {
        convertRes2Blob(res);
      } else {
        message.error('获取下载数据失败:' + (res.data.detail || ''));
      }
    };

    const inputResize = debounce(() => {
      if (reportInputPs) {
        reportInputPs.update();
      }
    }, 300);

    onMounted(() => {
      // console.log(process.env.NODE_ENV);
      reportTableDom = document.querySelector(
        '#optimize_report_body_scroll .ant-table-body'
      );
      if (reportTableDom) {
        reportTablePs = new PerfectScrollbar(reportTableDom);
      }

      reportInputDom = document.querySelector('#optimize_report_input_scroll');
      if (reportInputDom) {
        reportInputPs = new PerfectScrollbar(reportInputDom);
      }
      // console.log(reportInputDom,reportInputDom['prototype'], reportInputDom['click']);
      window.addEventListener('resize', inputResize);
    });
    onBeforeUnmount(() => {
      if (reportTablePs) {
        reportTablePs.destroy();
        reportTablePs = null;
      }
      reportTableDom = null;

      if (reportInputPs) {
        reportInputPs.destroy();
        reportInputPs = null;
      }
      reportInputDom = null;
      window.removeEventListener('resize', inputResize);
    });
    return {
      toolsShow,
      statusOpts,
      patsOpts,
      search,
      imgSvg,
      paginationProps,
      rowSelection,
      reportData,
      reportColumns,
      clearSearch,
      searchClick,
      toUrl,
      getReports,
      changeVariant,
      downloadFile,
      toQuaily,
      allDownload,
    };
  },
});
