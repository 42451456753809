export const formatVariant = (variantStr = ',,,,,,,') => {
  const _dataArr = variantStr.split(',');
  return {
    pat: _dataArr[0],
    pid: parseInt(_dataArr[1] || '0'),
    taskId: _dataArr[2],
    variant: _dataArr[3],
    error: parseFloat(_dataArr[4] || '0'),
    time: parseFloat(_dataArr[5] || '0'),
    isCommend: parseInt(_dataArr[6] || '0'),
    status: parseInt(_dataArr[7] || '0'),
  };
};