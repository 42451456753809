
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import ReportDetail from '../components/ReportDetail.vue'
import ReportTable from './ReportTable.vue'
import { message } from 'ant-design-vue';
import api, { ReportDetail as Detail} from '@/api/shader-detect'
import  store  from '@/vuex/store';

export default defineComponent({
  name:'OptimizeReport',
  components:{
    ReportDetail,
    ReportTable
  },
  setup() {
    const $_user = store.state.user.userinfo;
    const packages = ref([]);
    // for (let i = 1; i < 10; i++) {
    //   packages.value.push('League of Legends' + i);
    // }
    const getPackagesOfUser = async () => {
      const params = {
        user: $_user.email,
      };
      const res = await api.getPackagesOfUser(params);
      if (!res) return;
      if (res['code'] == 0) {
        console.log('getPackagesOfUser', res);
        packages.value = JSON.parse(res['result_list'] || '[]').filter(val => val != '');
        packageName.value = packages.value[packageActiveKey.value];
        getDetail();
      } else {
        message.error(`获取项目名列表失败:${res['detail'] || ''}`);
      }
    };
    getPackagesOfUser();

    const storePackage = computed(()=>store.state.report.packageActiveKey);
    const packageActiveKey = ref(storePackage.value);
    const packageName = ref('');
    const tabClick = (val)=>{
      console.log('tabClick', val)
      packageName.value = packages.value[val];
      store.commit('report/setPackageActiveKey', val);
      getDetail();
    }

    const detail = reactive<Detail>({
        /** 项目优化率 */
        optimize: 0,
        /** 节省时间 */
        time: 0,
        /** 平均误差 */
        error: 0,
        /** shader总数 */
        shader: 0,
        /** 已简化shader */
        simplify: 0,
        /** 共生成变体 */
        variant: 0,
        /** 共筛选变体 */
        filter: 0,
        /** 检测变体 */
        check: 0,
    });
    const getDetail = async () => {
      const params = {
        user: $_user.email,
        package_name: packageName.value
      };
      const res = await api.getOptimizationReportHeaderInfo(params);
      if (!res) return;
      if (res['code'] == 0) {
        console.log('getDetail', res);
        const _data = JSON.parse(res['result_list'] || '{}');
        detail.optimize = _data.optimization_rate * 100;
        detail.time = _data.time_saved * 100;
        detail.error = _data.error_average;
        detail.shader = _data.shader_count;
        detail.simplify = _data.simplified_shader;
        detail.variant = _data.variant_count;
        detail.filter = _data.replay_count;
        detail.check = _data.detected_variant;
      } else if(res['code'] == 34){
        message.info(`总览${res['detail']}，正在生成。。。`)
      } else {
        message.error(`获取优化报告总览失败：${res['detail'] || ''}`);
      }
    };

    const variantChange = ()=>{
      getDetail();
    }

    onMounted(()=>{
      
    })
  
    return {
      packages,
      packageActiveKey,
      packageName,
      detail,
      tabClick,
      variantChange
    }
  }
})
