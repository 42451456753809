
import { defineComponent, PropType } from 'vue';
import { ReportDetail } from '@/api/shader-detect'


export default defineComponent({
  name: 'ReportDetail',
  props: {
    /** 详细信息 */
    detail: {
      type: Object as PropType<ReportDetail>,
      default: {
        /** 项目优化率 */
        optimize: 0,
        /** 节省时间 */
        time: 0,
        /** 平均误差 */
        error: 0,
        /** shader总数 */
        shader: 0,
        /** 已简化shader */
        simplify: 0,
        /** 共生成变体 */
        variant: 0,
        /** 共筛选变体 */
        filter: 0,
        /** 检测变体 */
        check: 0,
      },
    },
  },
  setup() {},
});
