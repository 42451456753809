
import { defineComponent, reactive, watch, ref, inject } from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { formatVariant } from '@/utils';

/**
 * @description 变体信息
 * @name Variant
 * ```js
 * {
  pat: string;//pat名称
  pid: number;//shaderID
  taskId: string;//任务ID
  variant: string;//变体标识
  error: number;//平均误差
  time: number;//时间收益
  isCommend: number;//是否是推荐点，如果是为1，不是为0
  status: number;//标识该变体所处的状态，0标识未检测，1标识检测中，2标识已检测
 * }
 * ```
 */
interface Variant {
  pat: string;
  pid: number;
  taskId: string;
  variant: string;
  error: number;
  time: number;
  isCommend: number;
  status: number;
  [key: string]: any;
}

type element_null = Element | null;
export default defineComponent({
  name: 'VariantCard',
  emits: ['download', 'taskClick', 'changeClick'],
  props: {
    /** 变体信息 */
    variant: {
      type: String,
      default: ',0,,,0,0,1,0',
    },
    /** shader 的MD5标识 */
    shaderMd5: {
      type: String,
    },
    /** 该shader所包含于的所有pat */
    pats: {
      type: String,
    },
  },
  setup(props, context) {
    watch(
      () => props.variant,
      () => {
        console.log('watch variant');
        setCardData();
      }
    );

    const cardData = reactive<Variant>({
      pat: '',
      pid: 0,
      taskId: '',
      variant: '',
      error: 0,
      time: 0,
      isCommend: 1,
      status: 0,
    });
    const stat2icon = ['close', 'history', 'checkmark'];
    const stat2class = ['untest', 'testing', 'tested'];
    const stat2name = ['未检测', '检测中', '已检测'];

    const setCardData = () => {
      const _variant = formatVariant(props.variant);
      for (let key in _variant) {
        cardData[key] = _variant[key];
      }
    };
    setCardData();

    const taskStat2class = [
      'pending',
      'ready',
      'working',
      'stoping',
      'abort',
      'error',
      'finish',
      'deleted',
      'deleting',
    ];
    const taskStat2name = [
      '队列中',
      '队列中',
      '工作中',
      '停止中',
      '手动停止',
      '系统停止',
      '已完成',
      '已删除',
      '删除中',
    ];
    /** 检测任务列表 */
    const checkTasks = ref<any[]>([]);
    const taskSpin = ref<boolean>(true);
    let reportTaskDom: element_null = null;
    let reportTaskPs: any = null;
    const setTaskData = (tasks = []) => {
      checkTasks.value = [];
      for (let i = 0; i < tasks.length; i++) {
        const _tmp = tasks[i] as any;
        _tmp['id'] = i + 1;
        checkTasks.value.push(_tmp);
      }
      taskSpin.value = false;
    };
    const getTasks = inject<any>('getVariantTasks');
    const taskVisible = async (visible) => {
      console.log('taskVisible', visible, cardData.pid);
      if (visible) {
        taskSpin.value = true;
        console.log('open');
        setTaskData(
          await getTasks({
            pat: cardData.pat,
            pid: cardData.pid,
            variant: cardData.variant,
          })
        );
      } else {
        setTimeout(() => {
          if (reportTaskPs) {
            reportTaskPs.destroy();
            reportTaskPs = null;
          }
          reportTaskDom = null;
        }, 100);
      }
    };
    const taskResize = () => {
      console.log('taskResize:', cardData.pid);
      if (!reportTaskDom || !reportTaskPs) {
        reportTaskDom = document.querySelector(
          '#report_variant_card_task_scroll' + cardData.pid
        );
        if (reportTaskDom && !reportTaskPs) {
          reportTaskPs = new PerfectScrollbar(reportTaskDom);
        }
      }
    };

    const getVariants = inject<any>('getChangeVariants');
    const changeVariants = ref<Variant[]>([]);
    const changeSpin = ref<boolean>(true);
    let reportChangeDom: element_null = null;
    let reportChangePs: any = null;
    const setChangeData = (variants) => {
      changeVariants.value = [];
      for (let i = 0; i < variants.length; i++) {
        const _tmp = formatVariant((variants[i] as any).variant_id);
        _tmp['id'] = i + 1;
        _tmp['variant_id'] = variants[i].variant_id;
        changeVariants.value.push(_tmp);
      }
      changeSpin.value = false;
    };

    const changeVisible = ref(false);
    watch(changeVisible, async (visible) => {
      // console.log('changeVisible watch', visible, cardData.pid);
      if (visible) {
        taskSpin.value = true;
        setChangeData(
          await getVariants({ pats: props.pats, shader_md5: props.shaderMd5 })
        );
      } else {
        if (reportChangePs) {
          reportChangePs.destroy();
          reportChangePs = null;
        }
        reportChangeDom = null;
      }
    });
    const changeResize = () => {
      console.log('changeResize:', cardData.pid);
      if (!reportChangeDom || !reportChangePs) {
        reportChangeDom = document.querySelector(
          '#report_variant_card_change_scroll' + cardData.pid
        );
        if (reportChangeDom && !reportChangePs) {
          reportChangePs = new PerfectScrollbar(reportChangeDom);
        }
      }
    };
    const changeClick = (id, isReset) => {
      // console.log('changeClick', id);
      changeVisible.value = false;

      context.emit('changeClick', {
        tag: isReset ? 0 : 1,
        shader_md5: props.shaderMd5,
        of_pats: props.pats,
        variant_id: changeVariants.value[id - 1]?.variant_id || '',
      });
    };

    const changeOpen = () => {
      setTimeout(() => {
        changeVisible.value = true;
      }, 0);
    };

    const taskClick = (id) => {
      console.log('taskClick', id, checkTasks.value[id - 1]);
      const _item = checkTasks.value[id - 1] || {};
      if (_item.status == 6) {
        context.emit('taskClick', {
          pat: _item.pat_name || '',
          taskNumber: _item.task_number || '',
        });
      }
    };

    const handleMenuClick = ({ key }) => {
      context.emit('download', {
        type: key,
        pat: cardData.pat,
        shader_id: cardData.pid,
        task_id: cardData.taskId,
        variant: cardData.variant,
      });
    };

    return {
      cardData,
      stat2icon,
      stat2class,
      stat2name,
      taskStat2class,
      taskStat2name,
      checkTasks,
      taskSpin,
      changeVariants,
      changeSpin,
      changeVisible,
      taskVisible,
      taskResize,
      changeResize,
      changeClick,
      changeOpen,
      taskClick,
      handleMenuClick,
    };
  },
});
